<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('taxi.create taxi branch')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <AppSelectMulti
                :options="{containerCssClass: 'z-index-0',placeholder: $t('common.start_point')}"
                name="start_point"
                :label="$t('common.start_point')"
                rules="required"
                :options-data="startPoints"
                boundary-class="b-b-0"
                v-model="entryToSave.start_point_ids"/>
              <AppInput name="name" :label="$t('taxi.branch name')" type="string" rules="required"
                        v-model="entryToSave.name"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToSave.phone"/>
              <AppInput name="email" :label="$t('common.mail address')" type="email" rules="email"
                        v-model="entryToSave.email"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('taxi.update taxi branch')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <AppSelectMulti
                :options="{containerCssClass: 'z-index-0',placeholder: $t('common.start_point')}"
                name="start_point"
                :label="$t('common.start_point')"
                rules="required"
                :options-data="startPoints"
                boundary-class="b-b-0"
                v-model="entryToEdit.start_point_ids"/>
              <AppInput name="name" :label="$t('taxi.branch name')" type="string" rules="required"
                        v-model="entryToEdit.name"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToEdit.phone"/>
              <AppInput name="email" :label="$t('common.mail address')" type="email" rules="email"
                        v-model="entryToEdit.email"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('taxi.branch list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.TAXI_BRANCH_LIST,
          params: {"filters[taxi_company_id][equal]": this.$route.params.id}
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.TAXI_BRANCH_DELETE,
        }
      },
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        deleteEntry: true
      },
      tools: {
        search: {
          filters: []
        }
      },
      entrySelectedDelete: {},
      entryToSave: {},
      entryToEdit: {},
      startPoints: [],
      taxi: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {
          name: 'start_points', label: this.$t('common.start point'), sortable: false, computed: entry => {
            if (entry.start_points) {
              return entry.start_points.map(function (startPoint) {
                return startPoint.name
              }).join(', ');
            }
          }
        },
        {name: 'name', label: this.$t('taxi.branch name'), detailHeader: true},
        {name: 'phone', label: this.$t('common.phone'), sortable: false},
        {name: 'email', label: this.$t('common.mail address'), sortable: false},
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  watch: {
    'entryToEdit.start_point_ids': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.$refs.form_update_entry.setErrors({'start_point': null});
        }
      },
      deep: true
    },
    'entryToCreate.start_point_ids': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.$refs.form_create_entry.setErrors({'start_point': null});
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.TAXI_DETAIL(this.$route.params.id), null, {
      noLoadingEffect: true,
      handleNotFound: true
    }).then(res => {
      if (!res.hasErrors()) {
        this.taxi = res.data.data
        this.$store.commit('setting/updateBreadCrumb', {taxi_company: this.taxi.name})
      }
    })
    this.$request.get(this.$consts.API.ADMIN.START_POINT_LIST_SELECT, null, {
      noLoadingEffect: true,
    }).then(res => {
      if (!res.hasErrors()) {
        this.startPoints = res.data.data
      }
    })
  },
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {}
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {name, email, phone, start_point_ids} = this.entryToSave
      if (!start_point_ids.length) {
        this.$refs.form_create_entry.setErrors({
          'start_point': ['こちらは必須項目です。']
        });
        return false;
      }
      const res = await this.$request.post(this.$consts.API.ADMIN.TAXI_BRANCH_SAVE, {
        name, email, phone, taxi_company_id: this.taxi.id, start_point_ids
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {name, email, phone, start_point_ids} = this.entryToEdit
      if (!start_point_ids.length) {
        this.$refs.form_update_entry.setErrors({
          'start_point': ['こちらは必須項目です。']
        });
        return false;
      }
      const res = await this.$request.patch(this.$consts.API.ADMIN.TAXI_BRANCH_EDIT(this.entryToEdit.id), {
        name, email, phone, taxi_company_id: this.taxi.id, start_point_ids
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    /* UPDATE entry end */
  }
}
</script>
